import type {
    BaseConfig,
    UrlConfig,
    ModelUrlConfig,
    ModelUrlData,
    MenuConfig
} from '@preload/graphql/getConfig.d'

import type { HubSpotFormProps } from '@corratech/pylot-hubspotform'

export enum SubmenuMode {
    BLOCK = 'cms_block',
    SUBCATEGORY = 'subcategory'
}

export enum ModelTypeEnum {
    CATEGORY = 'category',
    PRODUCT = 'product',
    CMS_PAGE = 'cmsPage',
    BUNDLE = 'bundle'
}

export type ModelType = keyof Omit<ModelUrlConfig, '__typename'>

export type FrontendBaseConfig = BaseConfig & {
    cart_snapshot: {
        enabled: boolean
    }
    sellToUsForPlp: {
        enabled: boolean
        url: string
    }
    loqate: {
        enabled: boolean
        findEndPoint: string
        retrieveEndPoint: string
        validateEndPoint: string
        api_key: string
    }
    url: UrlConfig & {
        baseUrl: string
        model: {
            [key in ModelType]: ModelUrlData & {
                directory: string
            }
        }
        media: {
            cms: string
            home: string
        }
        path: {
            shippingSettings: string
        }
    }
    menu: Required<Omit<MenuConfig, '__typename'>> & {
        submenu: SubmenuMode | string
    }
    wishlist: {
        enabled: boolean
        multiple_wishlists: boolean
    }
    shipping: {
        addressCountry: string[]
        includeShippingDetails: boolean
    }
    yotpo: {
        enabled: boolean
        api_key: string
    }
    customer?: {
        gift_card_payment_enabled?: boolean
        store_credit_enabled?: boolean
    }
    price?: {
        currencySymbol: {
            [currency: string]: string
        }
    }
    checkout_timer: {
        enabled: boolean
        default_minutes: number
    }
    blog: {
        bannerCMS: string
        timezone: string
        home_page?: {
            title?: string
            breadcrumb_title?: string
        }
        is_recent_blog_post_cms_enabled: boolean | null
        recent_blog_post_cms: string | null
        investor_guide_cms: string | null
        news_facebook_iframe_cms: string | null
        is_news_facebook_iframe_enabled: boolean | null
    }
    customer_impersonation: {
        enabled: boolean
        quick_links: string
        popular_items: string
    }
    datadog: {
        enabled: boolean
        applicationId: string
        clientToken: string
        site: string
        service: string
        env: string
    }
    hubspot: {
        footer_newslestter: HubSpotFormProps
        pdp_form: HubSpotFormProps
        rsd_form: HubSpotFormProps
    }
    oos_subscription: {
        enabled: boolean
        gdrp_enabled: boolean
    }
    portfolio_tracker: {
        enabled: boolean
        v2_enabled: boolean,
        website_code: string
        portfolio_tracker_url: string
        portfolio_tracker_v2_script_url: string
        enable_buying_program: boolean
        script_url: string
        portfolio_alert_block: string
    }
    avalara: {
        address_validation_enabled: boolean
        token: string
        address_validation_enabled_countries: string[]
    }
    cart_min_order_amount: {
        enabled: boolean
        min_amount: number
        shipping_limit_amount: number
    }
    cart_min_order_notice: {
        title: string
        notice: string
    }
    check_out_additional: {
        ira_enabled: boolean
        order_comment_enabled: boolean
    }
    live_chat: {
        enabled: boolean
        license: string
        source: string
    }
}

export const base: FrontendBaseConfig = {
    cart_snapshot: {
        enabled: true
    },
    sellToUsForPlp: {
        enabled: true,
        url: 'sell-gold-silver'
    },
    product: {
        visibleListAttributes: ['sku', 'price_range', 'small_image', 'name'],
        pricing_table: true
    },
    loqate: {
        enabled: true,
        findEndPoint:
            'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
        retrieveEndPoint:
            'https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws',
        validateEndPoint:
            'https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws',
        api_key: ''
    },
    url: {
        baseUrl: 'https://www.magento-frontend.usgb.io/',
        model: {
            category: {
                directory: 'category'
            },
            product: {
                directory: 'product'
            },
            cmsPage: {
                directory: 'content'
            },
            giftCard: {
                directory: 'gift-card'
            },
            bundle: {
                directory: 'bundle'
            }
        },
        media: {
            cms: 'https://mcistaging.usgoldbureau.com/media/wysiwyg/logo/usgb-logo-pwa.png',
            home: 'https://mcistaging.usgoldbureau.com/media/wysiwyg/logo/usgb-logo-pwa.png'
        },
        path: {
            shippingSettings: 'content/ordering-info'
        }
    },
    menu: {
        submenu: SubmenuMode.SUBCATEGORY,
        submenu_maxitems: 10
    },
    wishlist: {
        enabled: true,
        multiple_wishlists: false
    },
    shipping: {
        addressCountry: ['AU, NZ, AL, DZ, EE, IR, IQ, AF'],
        includeShippingDetails: false
    },
    yotpo: {
        enabled: false,
        api_key: ''
    },
    customer: {
        gift_card_payment_enabled: true,
        store_credit_enabled: true
    },
    checkout_timer: {
        enabled: true,
        default_minutes: 10
    },
    blog: {
        bannerCMS: '',
        timezone: 'America/Panama',
        home_page: {
            title: 'Gold and Silver News',
            breadcrumb_title: 'Latest on Silver and Gold News'
        },
        recent_blog_post_cms: '',
        is_recent_blog_post_cms_enabled: false,
        investor_guide_cms: 'usgb_free_investor_kit_block',
        news_facebook_iframe_cms: 'usgb_facebook_page_plugin_block',
        is_news_facebook_iframe_enabled: false
    },
    customer_impersonation: {
        enabled: false,
        quick_links: '',
        popular_items: ''
    },
    datadog: {
        enabled: false,
        applicationId: '',
        clientToken: '',
        site: '',
        service: '',
        env: ''
    },
    hubspot: {
        footer_newslestter: {
            src: 'https://js.hsforms.net/forms/embed/v2.js',
            enabled: true,
            target: '#_usgb-magento-footer-form',
            region: 'na1',
            portalId: '21529994',
            formId: '701c3031-a56b-4038-a787-28fd24361a25'
        },
        pdp_form: {
            src: 'https://js.hsforms.net/forms/embed/v2.js',
            enabled: true,
            target: '#_usgb-magento-fik-form',
            region: 'na1',
            portalId: '21529994',
            formId: '3d71ecfb-73a4-4825-9fc6-802b9e66cc19'
        },
        rsd_form: {
            src: 'https://js.hsforms.net/forms/v2.js',
            enabled: true,
            target: '#rsd_hubspotForm',
            portalId: '21529994',
            region:'na1',
            formId: '90aa92ef-d334-4b1e-a56d-425c471816b6'
        }
    },
    oos_subscription: {
        enabled: true,
        gdrp_enabled: false
    },
    portfolio_tracker: {
        enabled: false,
        v2_enabled: false,
        website_code: '',
        portfolio_tracker_url: 'https://portfolio-tracker-dev.appspot.com',
        portfolio_tracker_v2_script_url: 'https://portfolio-tracker-frontend-stage.usgb.io/PortfolioTracker.umd.cjs',
        enable_buying_program: false,
        script_url:
            'https://cdnjs.cloudflare.com/ajax/libs/pym/1.3.2/pym-loader.v1.min.js',
        portfolio_alert_block: ''
    },
    avalara: {
        address_validation_enabled: true,
        token: 'mumt5ydkya2wcwp2lcbbqipbmzt6ff1q',
        address_validation_enabled_countries: ['CA', 'US']
    },
    cart_min_order_amount: {
        enabled: true,
        min_amount: 99,
        shipping_limit_amount: 99
    },
    cart_min_order_notice: {
        title: 'Minimum Order Amount $99',
        notice: 'Minimum order amount is $99 and includes FREE SHIPPING. Please add additional items to your cart to check out.'
    },
    check_out_additional: {
        ira_enabled: false,
        order_comment_enabled: false
    },
    live_chat: {
        enabled: false,
        license: '',
        source: ''
    }
}

export default base
